<script setup lang="ts">
import { PromotionType } from '@rediredi/types'
import { FALLBACK_IMAGE_PATH } from '~/consts/images'
import { IStorefrontItem } from '~/types/item'

const props = defineProps<{
  item: IStorefrontItem
}>()

const emit = defineEmits<{
  (e: 'view-product', item: IStorefrontItem): void
  (e: 'add-product', item: IStorefrontItem): void
}>()

const { event } = useEvent()
const theme = useStoreTheme()
const globalStore = useGlobalStore()
const currency = globalStore.storeCurrency
const isDiscountsEnabled = true

const handleClick = () => {
  emit('view-product', props.item)
  event('select_item', {
    item_list_id: 'main_search',
    item_list_name: 'Main search',
    items: [itemToAnalytics(props.item)],
  })
}

const imgSrc = computed(() => {
  const pictures = getCoverVariantPictures(props.item)
  if (pictures.length) return optimizedImage(getPictureUrl(pictures[0]), 'small')
  return FALLBACK_IMAGE_PATH
})

const oldPrice = computed(() => {
  if (!props.item.promotion?.showBeforeAndAfterPrice || !isDiscountsEnabled) return null

  return props.item.baseVariant.price
})
const price = computed(() => {
  const price =
    props.item.promotion && props.item.promotion.type !== PromotionType.Label && isDiscountsEnabled
      ? props.item.baseVariant.promotionPrice
      : props.item.baseVariant.price
  return price
})

const redirect = () => {
  useItemRedirect(props.item)
}

const isOutOfStock = computed(() => (props.item.quantityAvailable || 0) < 1)
</script>

<template>
  <div
    class="relative flex h-full cursor-pointer flex-col justify-between overflow-hidden rounded-xl bg-white p-4 transition-all duration-500 hover:shadow-xl"
  >
    <RProductIndicator
      v-if="isOutOfStock || props.item.promotion"
      :type="isOutOfStock ? 'outOfStock' : 'promotion'"
      :promotion="props.item.promotion"
      context="list"
    />

    <div class="flex gap-4">
      <div role="button" class="aspect-square h-12 w-12 self-start" @click="handleClick">
        <img alt="" loading="lazy" class="h-12 w-12 rounded-lg object-contain" :src="imgSrc" />
      </div>

      <div
        class="flex flex-1 flex-col items-start gap-2 self-center"
        role="button"
        @click="handleClick"
      >
        <p class="line-clamp-2 overflow-hidden text-ellipsis text-sm font-medium text-gray-400">
          {{ item.baseVariant.title }}
        </p>

        <div class="flex items-baseline justify-end gap-1 text-right text-sm text-gray-400">
          <div v-if="price?.amount" class="text-left">
            <template v-if="oldPrice">
              <div class="text-[12px] font-medium text-gray-400 line-through">
                <span>{{ formatPriceInParts(oldPrice.amount, { currency }).currency }}</span>
                <span>{{ formatPriceInParts(oldPrice.amount, { currency }).amount }}</span>
              </div>
            </template>

            <span class="font-bold">
              {{ formatPriceInParts(price.amount, { currency }).currency }}
            </span>
            <span class="text-base font-bold">
              {{ formatPriceInParts(price.amount, { currency }).amount }}
            </span>
          </div>

          <template v-else>
            <span class="font-bold">
              {{ $t('globals.noPrice') }}
              <span>&nbsp;</span>
            </span>
          </template>
        </div>
      </div>

      <div class="self-center">
        <RCartAddButton
          v-if="globalStore.isCheckoutEnabled"
          class="!h-8 !w-8"
          icon-only
          :item="props.item"
        />

        <button
          v-else
          class="m-auto flex h-8 w-8 items-center justify-center gap-2 rounded-full bg-whatsapp p-2 px-3"
          :style="{ color: theme.text }"
          @click="redirect"
        >
          <RIcon size="16" name="mdi:whatsapp" />
        </button>
      </div>
    </div>
  </div>
</template>
