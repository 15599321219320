<script lang="ts" setup>
const theme = useStoreTheme()
const props = defineProps<{ active: boolean; customActiveColor?: string }>()
defineEmits(['click'])

const color = computed(() => props.customActiveColor || theme.primary)

const border = computed(() => {
  return props.active ? color.value : 'white'
})
</script>

<template>
  <button
    class="btn-label flex-shrink-0 whitespace-nowrap rounded-3xl border-[2px] border-white bg-white px-[14px] py-1.5 text-[12px] font-medium sm:text-sm md:py-2"
    :style="{ color: color, borderColor: border }"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>
