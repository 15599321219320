<script setup lang="ts">
import { watchOnce } from '@vueuse/core'

const props = defineProps<{
  recordsPerPage: number
  totalRecords: number
  currentPage: number
}>()

const emit = defineEmits(['next-page'])
const endOfScroll = ref()

const intersectionObserver = new IntersectionObserver((entries) => {
  if (entries.some((entry) => entry.isIntersecting)) {
    const nextPage = props.currentPage + 1

    if (!props.totalRecords) return

    const maxPagesAvailable = Math.ceil(props.totalRecords / props.recordsPerPage)

    if (nextPage > maxPagesAvailable) return

    emit('next-page', nextPage)
  }
})

watchOnce(endOfScroll, (element) => {
  if (element) intersectionObserver.observe(element)
})

onUnmounted(() => {
  intersectionObserver.disconnect()
})
</script>

<template>
  <div>
    <slot />
    <span ref="endOfScroll"></span>
  </div>
</template>
