<script setup lang="ts">
const props = defineProps<{ items: string[] }>()
const theme = useStoreTheme()
const emit = defineEmits<{
  (e: 'click', name: string): void
}>()

const isLastItem = (index: number) => index + 1 === props.items.length
const handleClick = (name: string, index: number) => {
  if (isLastItem(index)) return
  emit('click', name)
}
</script>
<template>
  <ul class="flex items-center gap-2 flex-wrap">
    <template v-for="(item, index) in props.items" :key="item">
      <li
        class="text-gray-300 text-[12px] md:text-sm"
        :class="{ 'font-bold': isLastItem(index) }"
        :style="isLastItem(index) ? `color: ${theme.primary}` : ''"
        @click="handleClick(item, index)"
      >
        <button
          class="transition-colors duration-300"
          :class="{ 'cursor-default': isLastItem(index) }"
        >
          {{ item }}
        </button>
      </li>
      <li v-if="!isLastItem(index)" class="text-gray-300">/</li>
    </template>
  </ul>
</template>
<style scoped>
button:hover {
  color: var(--color-primary);
}
</style>
