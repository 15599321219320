<script setup lang="ts">
import { PromotionType } from '@rediredi/types'
import { FALLBACK_IMAGE_PATH } from '~/consts/images'
import { IStorefrontItem } from '~/types/item'

const props = defineProps<{
  item: IStorefrontItem
}>()

const emit = defineEmits<{
  (e: 'view-product', item: IStorefrontItem): void
  (e: 'add-product', item: IStorefrontItem): void
}>()
const globalStore = useGlobalStore()
const { event } = useEvent()
const theme = useStoreTheme()

const isDiscountsEnabled = true

const handleClick = () => {
  emit('view-product', props.item)
  event('select_item', {
    item_list_id: 'main_search',
    item_list_name: 'Main search',
    items: [itemToAnalytics(props.item)],
  })
}

const currency = globalStore.storeCurrency

const oldPrice = computed(() => {
  if (!props.item.promotion?.showBeforeAndAfterPrice || !isDiscountsEnabled) return null

  return props.item.baseVariant.price
})
const price = computed(() => {
  const price =
    props.item.promotion && props.item.promotion.type !== PromotionType.Label && isDiscountsEnabled
      ? props.item.baseVariant.promotionPrice
      : props.item.baseVariant.price
  return price
})

const imgSrc = computed(() => {
  const pictures = getCoverVariantPictures(props.item)
  if (pictures.length) return optimizedImage(getPictureUrl(pictures[0]), 'small')
  return FALLBACK_IMAGE_PATH
})

const redirect = () => {
  useItemRedirect(props.item)
}

const isOutOfStock = computed(() => (props.item.quantityAvailable || 0) < 1)
</script>

<template>
  <div
    class="relative flex h-full cursor-pointer flex-col justify-between overflow-hidden rounded-3xl bg-white px-3 py-4 transition-all duration-500 hover:shadow-xl lg:px-5 lg:py-6"
  >
    <RProductIndicator
      v-if="isOutOfStock || props.item.promotion"
      :type="isOutOfStock ? 'outOfStock' : 'promotion'"
      :promotion="props.item.promotion"
      context="grid"
    />

    <div role="button" class="h-full" @click="handleClick">
      <img
        alt=""
        loading="lazy"
        class="flex aspect-square w-full justify-center rounded-3xl object-contain align-middle"
        :class="{
          'p-8 sm:p-16': imgSrc === FALLBACK_IMAGE_PATH,
        }"
        :src="imgSrc"
      />

      <div class="flex flex-col gap-2 px-0 py-3 lg:gap-3 lg:px-1 lg:py-4">
        <p
          class="line-clamp-2 h-10 overflow-hidden text-ellipsis text-sm font-medium text-gray-400"
        >
          {{ item.baseVariant.title }}
        </p>

        <div class="flex flex-col items-baseline gap-1 text-left font-bold text-gray-400">
          <template v-if="price?.amount">
            <template v-if="oldPrice">
              <div class="text-sm font-medium text-gray-400 line-through">
                <span>{{ formatPriceInParts(oldPrice.amount, { currency }).currency }}</span>
                <span>{{ formatPriceInParts(oldPrice.amount, { currency }).amount }}</span>
              </div>
            </template>
            <div class="text-xl">
              <span>{{ formatPriceInParts(price.amount, { currency }).currency }}</span>
              <span>{{ formatPriceInParts(price.amount, { currency }).amount }}</span>
            </div>
          </template>
          <template v-else>
            <span v-if="props.item.promotion" class="text-xl">&nbsp;</span>
            <span class="text-base">
              {{ $t('globals.noPrice') }}
            </span>
          </template>
        </div>
      </div>
    </div>

    <button
      v-if="!globalStore.isCheckoutEnabled || isOutOfStock"
      class="m-auto flex w-full items-center justify-center gap-2 rounded-full bg-whatsapp p-2 px-3 py-3 text-xs font-semibold uppercase md:text-sm lg:px-8"
      :style="{ color: theme.text }"
      @click="redirect"
    >
      {{ isOutOfStock ? $t('components.buyButton.inquire') : $t('components.buyButton.label') }}
      <RIcon size="16" name="mdi:whatsapp" />
    </button>

    <RCartAddButton v-else :item="props.item" text-only @redirect="handleClick" />

    <button
      class="m-auto mt-3 p-0 text-xs font-semibold uppercase md:text-sm lg:mt-4"
      :style="{ color: theme.primary }"
      @click="handleClick"
    >
      {{ $t('components.productCell.secondaryButton.label') }}
    </button>
  </div>
</template>
