<script lang="ts" setup>
import { InputHTMLAttributes } from 'nuxt/dist/app/compat/capi'

type EnterKeyHit = 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send'

const props = defineProps<{
  modelValue: string
  clearable?: boolean
  inputProps?: InputHTMLAttributes & { enterKeyHint?: EnterKeyHit }
}>()
const elRef = ref()
const emit = defineEmits(['update:modelValue', 'enterPress', 'clear'])
const theme = useStoreTheme()

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.value ?? '')
}

const slots = useSlots()
const isClearButtonVisible = computed(() => props.clearable && props.modelValue)
defineExpose({
  el: elRef,
})
</script>

<template>
  <div class="relative">
    <input
      ref="elRef"
      :value="modelValue"
      class="w-full transition-all py-3 px-6 rounded-full focus:outline outline-[1.5px] text-gray-400 text-base sm:text-sm font-medium border-1 border-gray-200 bg-white placeholder:text-gray-300"
      :style="{ outlineColor: theme.primary }"
      v-bind="props.inputProps"
      @input="handleInput"
      @keyup.enter="emit('enterPress')"
    />

    <div
      v-if="slots['appended'] || isClearButtonVisible"
      class="absolute right-4 flex items-center justify-center"
      :class="[isClearButtonVisible ? 'top-[8px]' : 'top-[11px]']"
      :style="{ color: theme.primary }"
    >
      <RTransition name="grow-fade">
        <button
          v-if="isClearButtonVisible"
          class="text-xs rounded-full p-2"
          :style="{ color: theme.text, backgroundColor: theme.primary }"
          @click="emit('clear')"
        >
          {{ $t('components.input.clearButton.label') }}
        </button>

        <div v-else class="absolute right-0 top-0">
          <slot name="appended" />
        </div>
      </RTransition>
    </div>
  </div>
</template>
