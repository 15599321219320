export default {
  theme: {
    extend: {
      boxShadow: {
        'top-sm': '0px -6px 6px -6px rgba(97, 97, 97, 0.20) ',
        'top-md': '0px -1px 13px 0px rgba(97,97,97,0.20)',
      },
      colors: {
        alabaster: '#FBFBFB',
        'aqua-haze': '#FAFAFA',
        'bali-hai': '#8693B4',
        botticelli: '#DDE2EF',
        'mine-shaft': '#343434',
        white: '#FFFFFF',
        'light-gray': '#FEFEFE',
        mercury: '#E6E6E6',
        sushi: '#72B945',
        'dusty-gray': '#9B9B9B',
        silver: '#C6C6C6',
        'athens-gray': '#F3F3F5',
        alto: '#D8D8D8',
        'blue-haze': '#BFC6D9',
        'black-haze': '#EEF0F0',
        whatsapp: '#35CA50',
        'dark-mercury': '#E3E3E3',
        concrete: '#F3F3F3',
        primary: '#8693B4',
        secondary: '#737F9C',
        'egg-white': '#FFEFC5',
        'hokey-pokey': '#D1A32B',
        'carrot-orange': '#F38B2B',
        gray: {
          50: '#C1C1C1',
          100: '#F3F3F3',
          150: '#EFEFEF',
          200: '#E6E6E6',
          300: '#999999',
          350: '#828282',
          400: '#535353',
        },
        red: {
          100: '#FFD2D0',
          200: '#FA4B4B',
          300: '#E63030',
        },
        apple: '#4FB83E',
      },
      borderWidth: {
        1: '1px',
        1.5: '1.5px',
      },
      screens: {
        xs: '320px',
      },

      fontSize: {
        xs: '10px',
      },
    },
  },
}
