<script lang="ts" setup>
import type { MobileProductCellType } from '~/stores/global'

const globalStore = useGlobalStore()
const { t } = useI18n()
const setDisplay = (display: MobileProductCellType) => {
  globalStore.config.mobileProductCellVariant = display
}
</script>

<template>
  <div class="flex gap-0 justify-end font-medium text-[12px]">
    <RBaseButton
      class="button text-gray-300 p-2"
      color="none"
      :class="{ 'button--active': globalStore.config.mobileProductCellVariant === 'grid' }"
      :aria-label="t('globals.productCellTypes.grid')"
      @click="setDisplay('grid')"
    >
      <RIcon size="20" name="material-symbols:grid-view-outline-rounded" />
    </RBaseButton>

    <RBaseButton
      class="button text-gray-300 p-2"
      color="none"
      :class="{ 'button--active': globalStore.config.mobileProductCellVariant === 'list' }"
      :aria-label="t('globals.productCellTypes.list')"
      @click="setDisplay('list')"
    >
      <RIcon size="20" name="material-symbols:menu-rounded" />
    </RBaseButton>
  </div>
</template>

<style scoped>
.button--active {
  color: var(--color-primary) !important;
}
</style>
