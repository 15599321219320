<script lang="ts" setup>
const theme = useStoreTheme()
</script>

<template>
  <ClientOnly>
    <div
      role="status"
      class="flex items-center justify-center"
      :style="{ color: theme.primary }"
      v-bind="$attrs"
    >
      <RIcon size="60 " name="svg-spinners:bars-scale-fade" />
      <span class="sr-only">{{ $t('components.spinner.loading') }}</span>
    </div>
  </ClientOnly>
</template>
