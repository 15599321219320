<script lang="ts" setup>
import { useRouteQuery } from '@vueuse/router'
const store = useItemsStore()
const itemsStore = useItemsStore()
const currentStore = useCurrentStore()
const categoryQuery = useRouteQuery('c')
const isLoading = ref(!!categoryQuery.value)
const route = useRoute()

const fetchItems = async () => {
  isLoading.value = true
  const { data } = await itemsStore.searchItems()

  if (!store.hasInitiallyLoaded) {
    store.hasInitiallyLoaded = true
  }

  store.items.push(...data)
  isLoading.value = false
}

const nextPage = () => {
  itemsStore.currentPage = itemsStore.currentPage + 1
  fetchItems()
}

onBeforeRouteLeave(() => {
  store.scrollPosition = window.scrollY
})

onMounted(() => {
  window.scrollTo({
    top: store.scrollPosition,
    behavior: 'smooth',
  })
})

if (!itemsStore.items?.length && currentStore?.id && !categoryQuery.value) {
  if (route.query.promoId) {
    await itemsStore.loadPromotion()
  }

  fetchItems()
}
</script>

<template>
  <div>
    <RAppStoreBanner />

    <div class="m-auto max-w-[1280px] p-4 md:py-6">
      <RProductFilters @fetch="fetchItems" />

      <RProductZeroState v-if="itemsStore.totalRecords === 0" />

      <RProductList v-else :is-loading="isLoading" @next-page="nextPage" />
    </div>
  </div>
</template>
