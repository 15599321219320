<script setup lang="ts">
const handleClick = () => {
  const buildMode = import.meta.env.VITE_BUILD_MODE as 'prod' | 'dev'
  const isProd = buildMode === 'prod'

  const URLS: Record<'android' | 'ios', { dev: string; prod: string }> = {
    android: {
      dev: 'sample://app.dev.mobile.rediredi.com/products/create?ftu=true',
      prod: 'sample://app.mobile.rediredi.com/products/create?ftu=true',
    },
    ios: {
      dev: 'https://app.dev.mobile.rediredi.com/products/create?ftu=true',
      prod: 'https://app.mobile.rediredi.com/products/create?ftu=true',
    },
  }

  if (isMobileDevice()) {
    const device = isIOs() ? 'ios' : 'android'

    window.location.replace(URLS[device][buildMode])
  }

  const url = isProd
    ? 'https://app.rediredi.com/products/new'
    : 'https://app.dev.rediredi.com/products/new'

  window.open(url)
}
</script>

<template>
  <div class="mt-10 flex min-h-[300px] items-start justify-center">
    <div class="flex flex-col items-center gap-10 sm:flex-row">
      <div class="m-auto flex h-auto w-32 flex-1 items-center justify-center sm:w-64">
        <img alt="" src="/images/products/zero-state.png" />
      </div>

      <div class="flex max-w-[500px] flex-col items-center gap-6 sm:items-start">
        <p class="text-center text-base font-semibold text-gray-400 sm:text-left sm:text-lg">
          {{ $t('pages.productIndex.zeroState.title') }}
        </p>

        <button
          class="primary rounded-full p-3 px-8 text-sm font-semibold text-white"
          @click="handleClick"
        >
          {{ $t('pages.productIndex.zeroState.cta') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.primary {
  background-color: var(--color-primary);
}
</style>
